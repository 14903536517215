import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Box, useTheme } from '@mui/material';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CenteredContainer from '../../../../components/layout/CenteredContainer';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { SignupRegisterOverviewFormType, SignupRegisterOverview } from './SignupRegisterOverview';
import { SignupRegisterYourInfo, SignupRegisterYourInfoType } from './SignupRegisterYourInfo';
import { FormikProps } from 'formik';
import { SignupRegisterLocation, SignupRegisterLocationFormType } from './SignupRegisterLocation';
import { SignupRegisterPlanType, SignupRegisterPlanTypeFormType } from './SignupRegisterPlanType';
import { SignupRegisterPaymentInfo, SignupRegisterPaymentInfoType } from './SignupRegisterPaymentInfo';
import { SignupRegisterReview } from './SignupRegisterReview';
import { useSelector } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { getMyUserData } from '../../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { validateAndSubmitForm } from '../../../../shared/utils/gen-utils';
import { setClientOptions } from '../../../../store/actions/clientOptionsActions';
import { showErrorMessage } from '../../../../store/actions/genericAction';
import { useNavigate } from 'react-router-dom';
import StandardModal from '../../../../components/shared/StandardModal';

type RegisterStep = 'overview' | 'yourInfo' | 'location' | 'planType' | 'paymentInfo' | 'review';

const steps: RegisterStep[] = ['overview', 'yourInfo', 'location', 'planType', 'paymentInfo', 'review'];


export const SignupRegister = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  
const labels: Record<RegisterStep, string> = {
  overview: t('overview'),
  yourInfo: t('yourInfo'),
  location: t('location'),
  planType: t('planType'),
  paymentInfo: t('paymentInfo'),
  review: t('review'),
};

function compareSteps(orderedSteps: string[], current: string, next: string) {
  const currentIndex = orderedSteps.indexOf(current);
  const nextIndex = orderedSteps.indexOf(next);

  if (currentIndex === -1 || nextIndex === -1) {
    throw new Error('One or both of the steps are not found in the orderedSteps array');
  }

  if (nextIndex === currentIndex) {
    return 'same';
  } else if (nextIndex > currentIndex) {
    return 'after';
  } else {
    return 'before';
  }
}

const navigate = useNavigate();

const orderedSteps = ['overview', 'yourInfo', 'location', 'planType', 'paymentInfo', 'review'];
const orderedPatientPlanStatus = ["", "self-your-info", "self-plan-type", "self-payment-profile"]

  const [confirmChangeStepModalOpen, setConfirmChangeStepModalOpen] = useState(false);
  const [confirmChangeStepToNavigate, setConfirmChangeStepToNavigate] = useState('yourInfo');
  const [activeStep, setActiveStep] = useState<RegisterStep>('yourInfo');
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([]);

  const overview = useRef<FormikProps<SignupRegisterOverviewFormType>>(null);
  const yourInfo = useRef<FormikProps<SignupRegisterYourInfoType>>(null);
  const location = useRef<FormikProps<SignupRegisterLocationFormType>>(null);
  const planType = useRef<FormikProps<SignupRegisterPlanTypeFormType>>(null);
  const paymentInfo = useRef<FormikProps<SignupRegisterPaymentInfoType>>(null);
  const myPatientData = getMyPatientData();
  if (myPatientData.signup_complete){
    navigate("/Signup/Thankyou");
  }
  console.log("myPatientData", myPatientData)
  const theme = useTheme();

  const setCompleted = (step: RegisterStep) => {
    console.log("settings completed: ", step)
    completedSteps[steps.indexOf(step)] = true;
    setCompletedSteps([ ...completedSteps ]);
  };

  const isCompleted = (step: RegisterStep) => completedSteps[steps.indexOf(step)];

  const nextStep = useMemo(() => steps[completedSteps.indexOf(false)], [completedSteps]);

  const resolveIcon = useCallback((step: RegisterStep) => (isCompleted(step) ? <CheckCircleOutlineOutlined /> : undefined), [completedSteps]);

  const isDisabled = useCallback((step: RegisterStep) => !isCompleted(step) && step !== nextStep, [nextStep]);

  const clientOptions = useSelector((state) => state.main.clientOptions);


  const handleChange = (_: React.SyntheticEvent, newValue: RegisterStep) => {
    console.log("handling active step?", activeStep)

    const navDirection = compareSteps(orderedSteps, activeStep, newValue);
    if (navDirection === 'before') {
      setConfirmChangeStepToNavigate(newValue);
      setConfirmChangeStepModalOpen(true);
      return;
    }

    switch (activeStep){
      case "overview":
        if (overview.current) {
          const { validateForm, setTouched, submitForm, isValid } = overview.current;
    
          validateAndSubmitForm(
            validateForm,
            setTouched,
            submitForm,
            isValid,
            () => {
              dispatch(setClientOptions({ signUpStep: newValue }));
              console.log("overview valid")
            },
            (error: any) => {
              console.log("Form submission failed or has errors", error);
              dispatch(showErrorMessage("Please select a location"));
            }
          );
        }
        break;
      case "yourInfo":
        if (yourInfo.current) {
          const { validateForm, setTouched, submitForm, isValid } = yourInfo.current;
    
          validateAndSubmitForm(
            validateForm,
            setTouched,
            submitForm,
            isValid,
            () => {
              dispatch(setClientOptions({ signUpStep: newValue }));
            },
            (error: any) => {
              console.log("Form submission failed or has errors", error);
            }
          );
        }
        break;
        case "location":
          if (location.current) {
            const { validateForm, setTouched, submitForm, isValid } = location.current;
      
            validateAndSubmitForm(
              validateForm,
              setTouched,
              submitForm,
              isValid,
              () => {
                dispatch(setClientOptions({ signUpStep: newValue }));
              },
              (error: any) => {
                console.log("Form submission failed or has errors", error);
                dispatch(showErrorMessage("Please select a location"));
              }
            );
          }
          break;
          case "planType":
            if (planType.current) {


              const navDirection = compareSteps(orderedSteps, activeStep, newValue);
              console.log("navDirection", navDirection)
              if (navDirection === 'before') {
                if (myPatientData?.patient_plan?.signupSource) {
                  console.log("in check 1")
                  const signupStepStatusDir = compareSteps(orderedPatientPlanStatus, myPatientData?.patient_plan?.signupSource, 'self-payment-profile');
                  console.log("signupStepStatusDir", signupStepStatusDir)
                  if (signupStepStatusDir === 'after') {
                    dispatch(showErrorMessage("Please select plan type and continue to payment information"));
                    console.log("must select a plan type")
                    return;
                  }
                }
              }

              console.log("PROCEEDING TO SUBMIT?")

              const { validateForm, setTouched, submitForm, isValid } = planType.current;
        
              validateAndSubmitForm(
                validateForm,
                setTouched,
                submitForm,
                isValid,
                () => {
                  dispatch(setClientOptions({ signUpStep: newValue }));
                },
                (error: any) => {
                  console.log("Form submission failed or has errors", error);
                }
              );
            }
            break;
            case "paymentInfo":
              if (paymentInfo.current) {

                const { validateForm, setTouched, submitForm, isValid, values } = paymentInfo.current;
          
                validateAndSubmitForm(
                  validateForm,
                  setTouched,
                  submitForm,
                  isValid,
                  (data) => {
                    dispatch(setClientOptions({ signUpStep: newValue }));
                  },
                  (error: any) => {
                    console.log("whats the error?", error)
                    // if (values.useCCOnFile){
                    //   console.log("DISPATCH SUCCESS 2")
                    //   dispatch(setClientOptions({ signUpStep: newValue }));
                    // }
                    // else{
                    //   console.log("Form submission failed or has errors", error);
                    // }
                  }
                );
              }
              break;
      default:
        handleChangeOriginal(_, newValue);
        break;
    }
  }



  const handleChangeOriginal = (_: React.SyntheticEvent, newValue: RegisterStep) => {
    console.log("handle change triggered?")
    const refMap: { [key: string]: React.RefObject<any> | null } = {
      yourInfo: yourInfo,
      location: location,
      planType: planType,
      paymentInfo: paymentInfo,
    };
  
    const ref = refMap[activeStep];
    
    // If the current step ref is valid, submit the form, else set active step directly
    if (ref && ref.current) {
      console.log(ref.current)
      console.log(ref.current.isValid)
      if (ref.current.isValid) {
        console.log("try to submit form")
        ref.current.submitForm().then(() => {
          console.log("submitted form")
          setActiveStep(newValue);
          // if (ref.current.isValid) {
          //   console.log("set active step 0")
          //   setActiveStep(newValue)
          // };
        });
      } else {
        console.log("set active step 1")
        setActiveStep(newValue);
      }
    } else {
      // No validation required for steps like "overview" and "review"
      console.log("set active step 2")
      setActiveStep(newValue);
    }
  
    console.log("old value", activeStep);
    console.log("newValue", newValue);

    // setActiveStep(newValue);
  };
  useEffect(() => {
    const completedSteps = [
      true,
      myPatientData?.info_complete,
      myPatientData?.location_complete,
      myPatientData?.plan_type_complete,
      myPatientData?.payment_data_complete,
      false,
    ]
    console.log("completed steps", completedSteps)
    setCompletedSteps(completedSteps);
  }, [myPatientData?.info_complete, myPatientData?.location_complete, myPatientData?.plan_type_complete, myPatientData?.payment_data_complete]);

  useEffect(() => {
    if (clientOptions.signUpStep != null){
      console.log("set active step 3")
      setActiveStep(clientOptions.signUpStep);
    }
  }, 
  [clientOptions])

  useEffect(() => {
    console.log("set active step 4")
    if (nextStep) setActiveStep(nextStep);
  }, [nextStep]);

  return (
    <>

<StandardModal
				title={'Confirm Change Step'}
				open={confirmChangeStepModalOpen}
				onClose={() => setConfirmChangeStepModalOpen(false)}
				paragraphs={[`Would you like to change steps, if you do you will lose your progress on this step.`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setConfirmChangeStepModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
            setConfirmChangeStepModalOpen(false)
            dispatch(setClientOptions({ signUpStep: confirmChangeStepToNavigate }));
				
					}
				}]}
				customActions={[]}
			/>
    
     <CenteredContainer>
      <Box
        style={{ backgroundColor: 'white' }}
        sx={{
          width: '95vw',
          [theme.breakpoints.up('md')]: {
            width: 1200,
          },
        }}
      >
        <TabContext value={activeStep}>
          <TabList onChange={handleChange} variant="fullWidth" centered>
            {steps.map((step) => (
              <Tab key={step} icon={resolveIcon(step)} iconPosition="end" label={labels[step]} disabled={isDisabled(step)} value={step} />
            ))}
          </TabList>
          <TabPanel value="overview">
            <SignupRegisterOverview
              ref={overview}
              onSuccess={() => {
                console.log("should go to overview")
                setCompleted('overview');
              }}
            />
          </TabPanel>
          <TabPanel value="yourInfo">
            <SignupRegisterYourInfo
              ref={yourInfo}
              onSuccess={() => {
                dispatch(getMyUserData())
                setCompleted('yourInfo');
              }}
            />
          </TabPanel>
          <TabPanel value="location">
            <SignupRegisterLocation
              ref={location}
              onSuccess={() => {
                setCompleted('location');
              }}
            />
          </TabPanel>
          <TabPanel value="planType">
            <SignupRegisterPlanType
              ref={planType}
              onSuccess={() => {
                setCompleted('planType');
              }}
            />
          </TabPanel>
          <TabPanel value="paymentInfo">
            <SignupRegisterPaymentInfo
              ref={paymentInfo}
              onSuccess={() => {
                console.log("setting review complete here?")
                setCompleted('paymentInfo');
              }}
            />
          </TabPanel>
          <TabPanel value="review">
            <SignupRegisterReview
              onSuccess={() => {
                setCompleted('review');
              }}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </CenteredContainer>
    </>
   
  );
};
