import { all, call, put } from 'redux-saga/effects'; //call
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { GetFriendlyError } from '../../utils/general';
import { autoYields } from './schema';
import { eventChannel } from 'redux-saga';

const client = new HttpClient();

export async function genericGet(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.get(url + objectToQuery(data));
}

export async function genericPost(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.post(url, data);
}

export async function genericPut(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.put(url, data);
}

export async function genericUploadFiles(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.uploadFiles(url, data, data.passedOptions);
}

export async function genericDelete(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.delete(`${url}/${data.id}`);
}

export function genericWebsocketReceive(socket: any) {
  return eventChannel((emit) => {
    socket.on('data-sync', (data: any) => {
      console.log("SOCKET data sync triggered?", data)
      emit({ type: ACTIONS.SYNC_DATA_FROM_SOCKET, payload: data });
    });
    return () => {};
  });
}

export const genericSaga = {

  *showError(action: any): Generator {
    try {
      console.log("error action", action);
      yield put({ type: ACTIONS.ERROR, payload: {error: action.payload, epoch: new Date().getTime()} });

    } catch (e) {
      console.log(e);
    }
  },

  *augmentMainKey(action: any): Generator {
    try {
      yield put({ type: ACTIONS.AUGMENT_MAIN_KEY_SUCCESS, payload: {} });

    } catch (e) {
      console.log(e);
    }
  },

  *setStateKey(action: any): Generator {
    try {
      yield put({ type: ACTIONS.SET_STATE_KEY_SUCCESS, payload: action.payload });

    } catch (e) {
      console.log(e);
    }
  },

  *mergeStateKey(action: any): Generator {
    try {
      yield put({ type: ACTIONS.MERGE_STATE_KEY_SUCCESS, payload: action.payload });

    } catch (e) {
      console.log(e);
    }
  },

  *genericHandler(action: any, apiEndpoint: any, doAutoYields = true, passthroughActionName = ''): Generator {
    try {
      if (action.autoHandleLoading === true) {
        yield put({ type: ACTIONS.IS_LOADING });
      }
      const response = (yield call(apiEndpoint, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (doAutoYields){
          const yields = autoYields(response.data);
          if (yields.length > 0) {
            yield all(yields);
          }
        }
        if (passthroughActionName !== ''){
          yield put({ type: passthroughActionName, data: response.data });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        if (action.autoSuccessMessage){
          yield put({ type: ACTIONS.SUCCESS, payload: {message: action.autoSuccessMessage, epoch: new Date().getTime()} });
        }
        if (action.autoHandleLoading === true) {
          yield put({ type: ACTIONS.NOT_LOADING });
        }
      } else {
        console.log("response here?", response.status)
        throw response.status;
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
      if (action.autoHandleLoading === true) {
        yield put({ type: ACTIONS.NOT_LOADING });
      }
      if (action.autoHandleError === true) {
        const error = e as Error;
        const friendlyError = GetFriendlyError(error.message);
        yield put({ type: ACTIONS.ERROR, payload: {error: friendlyError, epoch: new Date().getTime()} });
      }
    }
  },

  *socketReceive(action: any): Generator {
    let socketPackage = action?.payload
    // what is data from server
    try {
      const yields = autoYields(action.payload);
      if (yields.length > 0) {
        console.log("DEBUG: yielding")
        yield all(yields);
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export default genericSaga;
