import { takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import authSaga from "./authSaga";
import clientOptionSaga from './clientOptionsSaga';
import timezoneSaga from "./timezoneSaga";
import resourceSaga from "./resourceSaga";
import awsSaga from './awsSaga';
import genericSaga, { genericDelete, genericGet, genericPost, genericUploadFiles } from './genericSaga';
import { socketInitFlow } from './socketSaga';

const GET = 'GET';
const POST = 'POST';
const EVERY = 'EVERY';
const LATEST = 'LATEST';
const LEADING = 'LEADING';

interface SagaConfig {
  actionType: string;
  method: typeof GET | typeof POST; // Add more HTTP methods as needed
  endpoint: string;
  take?: typeof EVERY | typeof LATEST | typeof LEADING;
}

export default function* rootSaga() {
  // Saga configuration array
  const sagaConfig: SagaConfig[] = [
    { actionType: ACTIONS.REQUEST_PASSWORD_RESET, method: POST, endpoint: 'api/auth/request-password-reset' },
    { actionType: ACTIONS.REFRESH_USER_DATA, method: GET, endpoint: 'api/auth/refresh-user-data' },
    { actionType: ACTIONS.UPDATE_MY_USER, method: POST, endpoint: 'api/user/update-my-user' },
    { actionType: ACTIONS.GET_USER, method: GET, endpoint: 'api/user/get-user' },
    { actionType: ACTIONS.GET_USERS, method: GET, endpoint: 'api/user/get-all' },
    { actionType: ACTIONS.SIGN_IN, method: POST, endpoint: 'login' },
    { actionType: ACTIONS.GET_MY_USER_DATA, method: GET, endpoint: 'api/user/get-my-user-data' },
    { actionType: ACTIONS.SIGNUP_UPSERT_YOUR_INFO, method: POST, endpoint: 'api/signup/upsert-your-info' },
    { actionType: ACTIONS.SIGNUP_CREATE_USER, method: POST, endpoint: 'api/signup/create-user' },
    { actionType: ACTIONS.GET_MY_PROFILE, method: GET, endpoint: 'api/resource/get-my-profile', take: EVERY },
    { actionType: ACTIONS.CONVERT_RESOURCES, method: POST, endpoint: 'api/resource/convert', take: EVERY },
    { actionType: ACTIONS.GET_GLOBAL_SETTINGS, method: GET, endpoint: 'api/global-settings/get' },
    { actionType: ACTIONS.UPDATE_GLOBAL_SETTINGS, method: POST, endpoint: 'api/global-settings/update' },
    { actionType: ACTIONS.GET_ALL_LOCATIONS, method: GET, endpoint: 'api/location/get-all-locations' },
    { actionType: ACTIONS.SIGNUP_SET_LOCATION, method: POST, endpoint: 'api/signup/set-location' },
    { actionType: ACTIONS.SIGNUP_UPDATE_FAMILY_MEMBERS, method: POST, endpoint: 'api/signup/update-family-members' },
    { actionType: ACTIONS.SIGNUP_UPSERT_PAYMENT_PROFILE, method: POST, endpoint: 'api/signup/upsert-payment-profile' },
    { actionType: ACTIONS.SIGNUP_SUBMIT_APPLICATION, method: POST, endpoint: 'api/signup/submit-application' },
    { actionType: ACTIONS.CONFIRM_EMAIL, method: POST, endpoint: 'api/auth/confirm-email' },
    { actionType: ACTIONS.UPSERT_SIGNNOW_DOCUMENT, method: GET, endpoint: 'api/signup/sign-now' },
  ];

  // Static action handlers that don't use generic POST/GET methods
  const staticSagas: Array<[string, (action: any) => void]> = [
    [ACTIONS.SHOW_ERROR, genericSaga.showError],
    [ACTIONS.AUGMENT_MAIN_KEY, genericSaga.augmentMainKey],
    [ACTIONS.SET_STATE_KEY, genericSaga.setStateKey],
    [ACTIONS.MERGE_STATE_KEY, genericSaga.mergeStateKey],
    [ACTIONS.LOGOUT, authSaga.logout],
    [ACTIONS.PASSWORD_RESET, authSaga.resetPassword],
    [ACTIONS.INIT_SOCKET, socketInitFlow],
    [ACTIONS.SET_CLIENT_OPTIONS, clientOptionSaga.setClientOptions],
    [ACTIONS.SET_TIMEZONE, timezoneSaga.setTimeZone],
    [ACTIONS.CREATE_UPLOAD_RESOURCE, resourceSaga.createUploadResource],
    [ACTIONS.UPLOAD_RESOURCE_FILE, resourceSaga.uploadResourceFile],
    [ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD, awsSaga.initPresignedUrlMultipartUpload],
    [ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD, awsSaga.initPresignedUrlSimpleUpload],
    [ACTIONS.COMPLETE_MULTIPART_UPLOAD, awsSaga.completeMultiPartUpload],
    [ACTIONS.SYNC_DATA_FROM_SOCKET, genericSaga.socketReceive],
  ];

  // Loop through static saga handlers and bind them with takeLatest or other effects
  for (const [actionType, handler] of staticSagas) {
    yield takeLatest(actionType, handler);
  }

  // Loop through saga configurations to bind generic handlers for GET/POST requests
  for (const { actionType, method, endpoint, take } of sagaConfig) {
    let sagaEffect = takeLatest;
    switch(take) {
      case EVERY:
        sagaEffect = takeEvery;
        break;
      case LATEST:
        sagaEffect = takeLatest;
        break;
      case LEADING:
        sagaEffect = takeLeading;
        break;
      default:
        sagaEffect = takeLeading;
    }
    const handler = method === GET ? genericGet : genericPost;

    yield sagaEffect(actionType, (action) =>
      genericSaga.genericHandler(action, (data: any) => handler(data, endpoint))
    );
  }
}