import { ACTIONS } from "../../enums/actions";

export const genericAction = (actionName: string) => {
  return (payload: any = {}, 
    onSuccess: (...args: any) => void = () => {}, 
    onFail: (...args: any) => void = () => {}, 
    autoSuccessMessage: string = "", 
    autoHandleError: boolean = true, 
    autoHandleLoading: boolean = true) => ({
    type: actionName,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null,
    autoSuccessMessage,
    autoHandleError,
    autoHandleLoading
  }
  )
};
export const showErrorMessage = genericAction (ACTIONS.SHOW_ERROR);

export const updateMainStateKey = genericAction (ACTIONS.AUGMENT_MAIN_KEY);

export const setStateKey = genericAction (ACTIONS.SET_STATE_KEY);

export const mergeStateKey = genericAction (ACTIONS.MERGE_STATE_KEY);